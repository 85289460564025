<template>
  <div class="media-gallery-carousel">
    <div v-if="productSold" class="gallery-sold-label">
      {{ $t('Sold') }}
    </div>
    <!-- <div class="media1 hidden-xs">
      <div class="media-gallery1" v-for="(images) in gallery" :key="images.src">
        <div class="placeholder"/>
      </div>
    </div> -->
    <div v-if="vimeoId" class="media hidden-xs">
      <div class="media-gallery">
        <div class="image-wrapper">
          <img
            @mouseenter="navigate(0)"
            @click="openOverlay"
            v-lazy="gallery[0].src"
            :alt="productName"
            width="750"
            height="750"
            class="media-gallery_image"
            ref="images"
          >
        </div>
      </div>
      <div class="media-gallery media-gallery-video">
        <div class="image-wrapper relative">
          <vue-vimeo-player class="vimeo-video" ref="player" :options="options" :video-id="vimeoId" @ready="onReady" />
        </div>

        <div v-if="stylistNotes" class="stylist-notes">
          <p class="stylist-notes__header">
            {{ stylistHeader }}
          </p>
          <p class="stylist-notes__paragraph" v-html="stylistNotes" />
        </div>
      </div>

      <div class="media-gallery" v-for="(images, key) in gallery.slice(1)" :key="images.src">
        <div class="image-wrapper">
          <img
            @mouseenter="navigate(key + 1)"
            @click="openOverlay"
            v-lazy="images.src"
            :alt="productName"
            width="750"
            height="750"
            class="media-gallery_image"
            ref="images"
          >
        </div>
      </div>
    </div>

    <div v-else class="media hidden-xs">
      <div class="media-gallery" v-for="(images, key) in gallery" :key="images.src" :class="vimeoId ? 'media-gallery-video' : ''">
        <div v-if="gallery.length % 2 !== 0 && vimeoId && gallery.length > 1" class="wrapper relative">
          <vue-vimeo-player class="vimeo-video" ref="player" v-if="key === gallery.length - 1 && vimeoId" :options="options" :video-id="vimeoId" @ready="onReady" />
        </div>
        <div class="image-wrapper">
          <img
            @mouseenter="navigate(key)"
            @click="openOverlay"
            v-lazy="images.src"
            :alt="productName"
            width="750"
            height="750"
            class="media-gallery_image"
            ref="images"
          >
        </div>
        <div v-if="key === 1 && stylistNotes" class="stylist-notes">
          <p class="stylist-notes__header">
            {{ stylistHeader }}
          </p>
          <p class="stylist-notes__paragraph" v-html="stylistNotes" />
        </div>
      </div>
      <div v-if="gallery.length % 2 == 0 && vimeoId" class="wrapper1 relative">
        <vue-vimeo-player class="vimeo-video" ref="player" :options="options" :video-id="vimeoId" @ready="onReady" />
      </div>
    </div>
    <i
      class="zoom-in fs28 icon-search h4 p15 pointer cl-black bg-cl-white"
      @click="openOverlay"
      @mouseenter="navigate(0)"
    />
  </div>
</template>

<script>
import { getThumbnailPath as _thumbnailHelper } from '@vue-storefront/core/helpers'
import config from 'config'
import { vueVimeoPlayer } from 'vue-vimeo-player'
import ProductImage from './ProductImage'
import ProductVideo from './ProductVideo'
import reduce from 'lodash-es/reduce'
import map from 'lodash-es/map'

export default {
  name: 'ProductGalleryCarousel',
  components: {
    'Carousel': () => import(/* webpackChunkName: "vue-carousel" */ 'vue-carousel').then(Slider => Slider.Carousel),
    'Slide': () => import(/* webpackChunkName: "vue-carousel" */ 'vue-carousel').then(Slider => Slider.Slide),
    ProductImage,
    vueVimeoPlayer,
    ProductVideo
  },
  props: {
    gallery: {
      type: Array,
      required: true
    },
    stylistNotes: {
      type: String,
      default: ''
    },
    stylistHeader: {
      type: String,
      default: ''
    },
    vimeoId: {
      type: String,
      default: ''
    },
    productName: {
      type: String,
      default: ''
    },
    productSold: {
      type: Boolean,
      default: false
    },
    configuration: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      minHeight: this.gallery.length / 2 * 575,
      carouselTransition: true,
      carouselTransitionSpeed: 0,
      currentColor: 0,
      currentPage: 0,
      hideImageAtIndex: null,
      options: {
        muted: true,
        autoplay: true,
        loop: true
      },
      playerReady: true
    }
  },
  computed: {},
  beforeMount () {
    this.$bus.$on('product-after-configure', this.selectVariant)
    this.$bus.$on('product-after-load', this.selectVariant)
  },
  mounted () {
    this.selectVariant()
    if (this.configuration.color) {
      const { color } = this.configuration
      this.currentColor = color.id
    }

    this.$emit('loaded')
  },
  beforeDestroy () {
    this.$bus.$off('product-after-configure', this.selectVariant)
    this.$bus.$off('product-after-load', this.selectVariant)
  },
  methods: {
    getThumbnailPath (url, width, height) {
      return _thumbnailHelper(url, width, height, 'banners')
    },
    showImageList (index) {
      let perPage = 6
      return index + 1 <= perPage
    },
    onReady () {
      this.playerReady = true
    },
    play () {
      this.$refs.player.play()
    },
    pause () {
      this.$refs.player.pause()
    },
    navigate (index) {
      if (index < 0) return
      this.currentPage = index
    },
    async selectVariant (configuration) {
      await this.$nextTick()
      if (config.products.gallery.mergeConfigurableChildren) {
        const option = reduce(map(this.configuration, 'attribute_code'), (result, attribute) => {
          result[attribute] = this.configuration[attribute].id
          return result
        }, {})
        if (option) {
          let index = this.gallery.findIndex(
            obj => obj.id && Object.entries(obj.id).toString() === Object.entries(option).toString(), option)
          if (index < 0) index = this.gallery.findIndex(obj => String(obj.id && obj.id.color) === String(option.color))
          this.navigate(index)
        }
      }

      this.$emit('close')
    },
    openOverlay () {
      const currentSlide = this.currentPage
      this.$emit('toggle', currentSlide)
    },
    switchCarouselSpeed () {
      const { color } = this.configuration
      if (color && this.currentColor !== color.id) {
        this.currentColor = color.id
        this.carouselTransitionSpeed = 0
      } else {
        this.carouselTransitionSpeed = 500
      }
    },
    pageChange (index) {
      this.switchCarouselSpeed()
      this.hideImageAtIndex = null
    },
    onVideoStarted (index) {
      this.hideImageAtIndex = index
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/animations/transitions';
.vimeo-video {
  /deep/ iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
.wrapper {
  padding-left: 100%;
}
.wrapper1 {
  padding-bottom: 80%;
  padding-left: 100%;
}
.media-gallery-carousel {
  display: flex;
  position: relative;
  text-align: center;
  &__thumbs {
    margin-top: 5px;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    max-height: 520px;
    overflow-x: hidden;
    overflow-y: auto;
    white-space: nowrap;

    @media (max-width: 767px) {
      flex-direction: column;
      display: none;
    }
  }

  &__thumb {
    margin: 0 5px 20px 5px;
    cursor: pointer;
    display: inline-flex;

    span {
      margin: 0 auto;
    }
    &:last-of-type {
      margin-bottom: 0;
    }

    img {
      opacity: 0.9;
      display: block;
      max-width: 100%;
      width: auto;
      // height: 90px;
      &:hover {
        opacity: 1;
      }
    }
  }
}
.zoom-in {
  position: absolute;
  bottom: 0;
  right: 0;
}
.placeholder{
  padding-top: 143.33%;
}
.image{
  opacity: 1;
  will-change: opacity;
  transition: .3s opacity $motion-main;
  &:hover{
    opacity: .9;
  }
}
.video-container {
  align-items: center;
  justify-content: center;
}
.media {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.media-gallery {
  flex: 0 0 calc(50.33% - 20px);
  padding: 5px;
  flex-direction: row;
  }
.media-gallery-video:last-child {
  .media-gallery_image {
    padding-left: 10px;
  }
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.image-wrapper {
  padding-bottom: 150%;
  position: relative;
  overflow: hidden;
}
.media-gallery_image {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  cursor: pointer;
  height: auto;
  width: 100%;
  display: block;
}
.stylist-notes {
  transform: translateX(-50%);
}
.stylist-notes__header {
  color: #646464;
  font-size: 14px;
  padding: 5px;
  margin-top: 50px;
}
.stylist-notes__paragraph {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #646464;
  line-height: 2;
  font-size: 14px;
  padding: 5px;
  margin-bottom: 40px;
}
.media-gallery_image:hover {
  transform: scale(1.03) translateY(-50%);
  transition: 1s all $motion-main;
}

</style>

<style lang="scss">
.media-gallery-carousel,
.media-zoom-carousel {
  .VueCarousel-pagination {
    position: absolute;
    bottom: 15px;
    @media (max-width: 767px) {
      display: none;
    }
  }
  .VueCarousel-navigation-button {
    margin: 0;
    transform: translateY(-50%) !important;
  }
  .VueCarousel-slide {
    backface-visibility: unset;
  }
  .VueCarousel-navigation {
    opacity: 0;
    &--disabled {
      display: none;
    }
  }
  .VueCarousel-dot {
    padding: 8px !important;
    button {
      border: 2px solid #828282;
    }
  }
  &:hover {
    .VueCarousel-navigation {
      opacity: .9;
    }
    .VueCarousel-navigation-button {
      transition: opacity 3s;
      &:after {
        background-color: transparent;
      }
    }
  }
}
.gallery-sold-label {
  cursor: default;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 5;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.55px;
  color: #FFFFFF;
  background-color: #000;
  border: 1px solid #FFFFFF;
  border-radius: 3px;
  padding: 5px 12px;
  text-transform: uppercase;
}
</style>
